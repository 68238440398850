import { getMapsHelper } from  './util/mapsHelper'

export const initHomeMap = () => { 
    const mapHelper = getMapsHelper();

    mapHelper.ready()
        .then(() => {
            const theMap = mapHelper.createMap({
                element: document.querySelector('#map_canvas'),
                locationElementSelector: '#map_canvas',
            })

            theMap.addListener('idle', () => {
                theMap.setZoom(15);
            })
        })
}